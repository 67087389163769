<template>
  <div class="p-1">
    <p
      class="saj-header p-1"
      style="text-align: center;"
    >
      {{ $t('Performance Grade') }}
    </p>
    <validation-observer ref="validateScore">
      <b-table
        style=""
        :items="scoreArray"
        :fields="fields2"
        label-size="lg"
        bordered
      >
        <template #head()="data">
          <span
            class="saj-text d-flex justify-content-center"
          >{{ $t(data.label) }}</span>
        </template>
        <template #cell(index)="data">
          <div
            class="saj-text d-flex justify-content-center"
          >
            {{ currentPage === 1 ? data.index + 1 : (data.index + 1)+((currentPage) *10) }}
          </div>
        </template>
        <template #cell(grade)="data">
          {{ $t(data.item.grade) }}
        </template>
        <template #cell(min)="data">

          <div
            class="d-flex align-items-center justify-content-center p-1"
          >
              <b-form-input
                v-model="scoreArray[data.index].min"
                tabindex="0"
                class="d-flex justify-content-center align-items-center saj-title pointer"
                style="color: black; font-size: 14px; width: auto; padding-right: 10px; padding-left: 10px; border: 0.5px solid grey; height: 35px; width: 55px; border-radius: 0px; text-align: center"
                disabled
              />
             
          </div>
        </template>
        <template #cell(max)="data">

          <div
            class="d-flex align-items-center justify-content-center p-1"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t(data.item.grade)+' max score'"
              :rules="{
                required,
                min_value:data.index !== (scoreArray.length-1) ? scoreArray[data.index].min+1 : 100.00,
              }"
            >
              <b-form-input
                v-model="scoreArray[data.index].max"
                type="number"
                step="0.01"
                tabindex="0"
                class="d-flex justify-content-center align-items-center saj-title pointer"
                style="color: black; font-size: 14px; width: auto; padding-right: 10px; padding-left: 10px; border: 0.5px solid grey; height: 35px; width: 55px; border-radius: 0px; text-align: center"
                :disabled="data.index === (scoreArray.length - 1) || (scoreArray[data.index].min === 0 && data.index !== 0)"
                @change="scoreArray[data.index].max = changeInt(scoreArray[data.index].max), scoreArray[data.index+1].min = scoreArray[data.index].max + 0.01"
              />
              <small
                class="text-danger"
                style="color: #EA5455 !important;
                          position: absolute;
                          white-space: nowrap;
                          right: 30px;
                        "
              >{{ errors[0] }}</small>
            </validation-provider>
          <!-- score list{{scoreList[data.index].max}}
          score array{{scoreArray[data.index].max}} -->
          </div>
        </template>
      </b-table>
    </validation-observer>

    <div class="d-flex justify-content-center">
      <b-button
        class="mr-1 saj-button pointer"
        style="
                color: white;
                background: #ff0000 !important;
                border-color: #ff0000 !important;
              "
        @click="cancel()"
      > {{ $t('Cancel') }} </b-button>
      <b-button
        class="saj-button pointer"
        style="
               color: white;
               background: #0b103c !important;
               border-color: #0b103c !important;
             "
        @click="saveNewScore()"
      >
        {{ $t('Save') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BTable,
  BButton,
  BFormInput,
} from 'bootstrap-vue'
import {
  max_value,
  min_value,
} from 'vee-validate/dist/rules'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required } from '@validations'
import SAJToast from '@/component/saj-toastification.vue'

extend('max_value', max_value)
extend('min_value', min_value)

export default {
  components: {
    BTable,
    BButton,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    scoreList: {
      type: Array,
    },
    subId: {
      type: Number,
      default: null,
    },

  },
  data(){
    return {
      currentPage: 1,
      fields2: [
        {
          key: 'index', label: 'No.', thClass: 'text-center',
        },
        {
          key: 'grade', label: 'Performance Grade', tdClass: 'text-center',
        },
        {
          key: 'min', label: 'Minimum Score', tdClass: 'text-center',
        },
        {
          key: 'max', label: 'Maximum Score', tdClass: 'text-center',
        },
      ],
      required,
      scoreArray: [],

    }
  },
  mounted(){
    this.scoreArray = this.scoreList.map(x => ({
      ...x,
    }))
  },
  methods: {
    changeInt(a){
      // eslint-disable-next-line no-restricted-globals
      // console.log('a', a)
      let val = 0
      // eslint-disable-next-line no-restricted-globals
      if (a !== ''){
        val = parseFloat(a) + 0.00
      }
      return val
    },
    cancel(){
      this.$emit('cancel-save')
    },
    saveNewScore(){
      this.$refs.validateScore.validate().then(success => {
        if (success){
      const data = new FormData()

      data.append('subsidiary_id', this.subId)

      this.scoreArray.forEach(x => {
        data.append('grade[]', x.grade)
        data.append('min[]', x.min)
        data.append('max[]', x.max)
      })
      const config = {
        method: 'post',
        url: `${this.$baseUrl}/performance_grades/store`,
        data,
      }

      this.$axios(config)
        .then(response => {
          const res = response.data
          if (res.success) {
            this.$emit('success-update')
            this.$toast(
              {
                component: SAJToast,
                props: {
                  title: `${this.$t('Successfully saved')}!`,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  titleColor: '#000',
                },
              },
              {
                position: "top-right",
                type: 'info',
              },
            )
          } else {
            this.$swal(
              res.message,
              ``,
              'error',
            )
          }
        })
        .catch(() => {
          // console.log(error)
        })
        }
      })
    },
  },
}
</script>
