<template>
  <div class="">
    <b-card
      class=""
      style="
            "
    >
      <div class="row align-items-end px-1 mb-2">
        <!-- if admin -->
        <template>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0"
          >
            <label class="saj-text">{{ $t("Subsidiary") }}:</label>
            <b-form-select
              v-model="selectedSub"
              size="md"
              class="saj-text"
              :options="subsidiaryList"
              @change="showTable = false"
            />
          </b-col>
          <b-col>
            <b-button
              variant="primary"
              class=""
              block
              style=""
              :disabled="selectedSub === null"
              @click="getPerformanceGrade(), show = true"
            >

              <span class="saj-button d-md-none d-lg-block"> {{ $t("Search") }} </span>
            </b-button>
          </b-col>
          <b-col>
            <b-button
              variant="primary"
              class=""
              block
              style=""
              @click="clearSearch()"
            >
              <span class="saj-button d-md-none d-lg-block"> {{ $t("Clear") }}</span>
            </b-button>
          </b-col>
        </template>
      </div>
      <div
        class="p-1"
      >
        <!-- {{ gradeList }} -->
        <b-overlay
          :show="show"
          rounded="sm"
          class="mt-4"
          style="height: 100%;"
        >

          <template #overlay>
            <div class="d-flex flex-column align-items-center justify-content-center">
              <b-spinner
                variant="primary"
                label="Spinning"
              />
              <p class="mt-1">
                {{ $t('Fetching Data') }}...
              </p>
            </div>

          </template>
          <div
            v-if="showTable"
            class="mt-1 pl-1 pr-1"
          >
            <div class="mt-1 mb-1 saj-title d-flex justify-content-end">
              <b-button
                class=""
                variant="primary"
                style="
                        color: white !important;
                        "
                @click="createNewScore()"
              >
                {{ $t("Create New Score") }}
              </b-button>
            </div>
            <b-table

              class="styleTable"
              style=""
              :items="gradeList"
              :fields="fields"
              label-size="lg"
              bordered
            >
              <template #head()="data">
                <span
                  class="saj-text d-flex justify-content-center"
                >{{ $t(data.label) }}</span>
              </template>
              <template #cell(index)="data">
                <div
                  class="saj-text d-flex justify-content-center"
                >
                  {{ currentPage === 1 ? data.index + 1 : (data.index + 1)+((currentPage) *10) }}
                </div>
              </template>
              <template #cell(grade)="data">
                {{ $t(data.item.grade) }}
              </template>
              <!-- <template #cell(grade)="">
                    <div
                      class="saj-text d-flex justify-content-start"
                    >
                      {{ supeHRList.grade }}
                    </div>
                  </template> -->
              <!-- <template #cell(top_n)="">
                    <div
                      class="saj-text d-flex justify-content-center"
                      style=""
                    >
                      <div
                        class="row"
                      >
                        <div class="saj-text d-flex justify-content-center align-items-center">
                          <b-form-input
                            v-model="minimum"
                            type="number"
                            class="saj-form-text"
                          />
                          <span class="m-1">{{ $t("to") }}</span>
                          <b-form-input
                            v-model="maximum"
                            type="number"
                            class="saj-form-text"
                          />
                        </div>
                      </div>
                    </div>
                  </template> -->
            </b-table>
          </div>
        </b-overlay>
      </div>
    </b-card>
    <b-modal
      id="new-score"
      :hide-footer="true"
      :hide-header="true"
      :centered="true"
      size="lg"
    >
      <div>
        <new-score
          :score-list="gradeList"
          :sub-id="selectedSub"
          @success-update="$bvModal.hide('new-score'), getPerformanceGrade()"
          @cancel-save="$bvModal.hide('new-score')"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCol,
  BCard,
  BButton,
  BFormSelect,
  // BFormInput,
  BTable,
  BOverlay,
  BSpinner,
  BModal,
  // BRow,
} from "bootstrap-vue"
// import axios from "axios"
// import SAJToast from '@/component/saj-toastification.vue'
import NewScore from '@/views/pages/new_score.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BCol,
    BCard,
    BButton,
    BFormSelect,
    // BFormInput,
    BTable,
    BOverlay,
    BSpinner,
    BModal,
    // BRow,
    NewScore,
  },
  data() {
    return {
      show: false,
      showTable: false,
      gradeList: [],
      minimum: null,
      maximum: null,
      HrList: [],
      currentPage: 1,
      showDetails: false,
      selectedSub: null,
      subsidiaryList: [{ text: `${this.$i18n.t('Choose Subsidiary')}`, value: null, disabled: true }],
      showTabs: false,
      fields: [
        {
          key: 'index', label: 'No.', thClass: 'text-center',
        },
        {
          key: 'grade', label: 'Performance Grade', tdClass: 'text-center',
        },
        {
          key: 'min', label: 'Minimum Score', tdClass: 'text-center',
        },
        {
          key: 'max', label: 'Maximum Score', tdClass: 'text-center',
        },
      ],
      currVal: 0,
    }
  },
  computed: {
    ...mapGetters(['roles']),
    isEnglish() {
      return this.$i18n.locale === 'en'
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$i18n.locale': function () {
      this.subsidiaryList = this.subsidiaryList.map(x => {
        if (x.value === null){
          return {
            ...x,
            text: this.$t('Choose Subsidiary'),
          }
        }
        return x
      })
    },
  },
  mounted() {
    if (this.roles.selectedRole !== 1){
      this.getCurrentUser()
    }
    this.getSubsidiaryList()
  },

  methods: {
    getSubsidiaryList(){
      this.$axios.get(`${this.$baseUrl}/subsidiary/getAll`).then(res => {
        const sub = res.data.data.subsidiaries

        sub.forEach(data => {
          this.subsidiaryList.push({
            text: data.subsidiary_name,
            value: data.id,
          })
        })
      })
    },
    getCurrentUser(){
      this.$axios.get(`${this.$baseUrl}/users/current_user`).then(response => {
        // console.log('Current User', response)
        this.selectedSub = response.data.data.employee.business_unit
      })
    },
    clearSearch(){
      this.showTable = false
      if (this.roles.selectedRole === 1){
        this.selectedSub = null
      }
    },
    getPerformanceGrade() {
      const params = new URLSearchParams()

      params.append(`subsidiary_id`, this.selectedSub)

      this.$axios.get(`${this.$baseUrl}/performance_grades/get_by_subsidiary`, { params })
        .then(response => {
          this.gradeList = response.data.data
          // console.log("hereeeee perf grade", response)
          this.showTable = true
          this.show = false
        })
        .catch(() => {
          // console.log(error)
        })
    },
    createNewScore(){
      this.$swal({
        title: `${this.$i18n.t('Are you sure to create new score set?')}`,
        text: `${this.$i18n.t("This will replace the current score set!")}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: `${this.$i18n.t('Confirm')}`,
        cancelButtonText: `${this.$i18n.t('Cancel!')}`,
      }).then(result => {
        if (result.isConfirmed) {
          this.$bvModal.show('new-score')
        }
      })
    },
  },
}
</script>
<style scoped>
.form-control {
    width: 13%;
}
</style>
